// Initialize Firebase
export const firebaseConfig = {
    apiKey: "AIzaSyAMAOa6hqGPA9C0WMQZpgXTpKdWer0n6KA",
  authDomain: "techglia-jeopardy.firebaseapp.com",
  databaseURL: "https://techglia-jeopardy.firebaseio.com",
  projectId: "techglia-jeopardy",
  storageBucket: "techglia-jeopardy.appspot.com",
  messagingSenderId: "24315561598",
  appId: "1:24315561598:web:bf3f07c23ded32ef"
};
