import { Injectable } from '@angular/core';
import { AngularFirestoreDocument } from '@angular/fire/firestore';

import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    public userProfile: AngularFirestoreDocument;
    public currentUser: firebase.User;
    public profileData;

    constructor(private fs: FirestoreService) {
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                this.currentUser = user;
                this.userProfile = fs.doc(`/userProfiles/${user.uid}`);
                this.fs.doc$(this.userProfile).subscribe(data => {
                    this.profileData = data;
                });
            }
        });
    }

    getUid() {
        return this.profileData.uid;
    }

    isAdmin(): boolean {
        return this.isRole("admin");
    }
    
    isMember(): boolean {
        return this.isRole("member");
    }

    isRole(role: string): boolean {
        if (!this.currentUser) return false
        if (!this.profileData) return false
        if (this.profileData.roles[role]) {
            return true
        };
        return false;
    }

    getUserProfile(): AngularFirestoreDocument {
        return this.userProfile;
    }

    updateName(firstName: string, lastName: string): Promise<any> {
        return this.userProfile.update({ firstName, lastName });
    }

    updateDOB(birthDate: string): Promise<any> {
        return this.userProfile.update({ birthDate });
    }

    updateEmail(newEmail: string, password: string): Promise<any> {
        const credential: firebase.auth.AuthCredential = firebase.auth.EmailAuthProvider.credential(
            this.currentUser.email,
            password
        );

        return this.currentUser
        .reauthenticateAndRetrieveDataWithCredential(credential)
        .then(() => {
            this.currentUser.updateEmail(newEmail).then(() => {
                this.userProfile.update({ email: newEmail });
            });
        })
        .catch(error => {
            console.error(error);
        });
    }

    updatePassword(newPassword: string, oldPassword: string): Promise<any> {
        const credential: firebase.auth.AuthCredential = firebase.auth.EmailAuthProvider.credential(
            this.currentUser.email,
            oldPassword
        );

        return this.currentUser
        .reauthenticateAndRetrieveDataWithCredential(credential)
        .then(() => {
            this.currentUser.updatePassword(newPassword).then(() => {
                console.log('Password Changed');
            });
        })
        .catch(error => {
            console.error(error);
        });
    }
}
