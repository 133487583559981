import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/user/auth.guard';
import { AdminGuard } from './services/user/admin.guard';
import { MemberGuard } from './services/user/member.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: './pages/home/home.module#HomePageModule',
    canActivate: [MemberGuard]
  },
  {
    path: 'list',
    loadChildren: './pages/list/list.module#ListPageModule',
    canActivate: [AdminGuard]
  },
  { 
      path: 'login', 
      loadChildren: './pages/login/login.module#LoginPageModule' 
  },
  { path: 'about', 
    loadChildren: './pages/about/about.module#AboutPageModule'
  },
  // { path: 'chats/:id', 
  //   loadChildren: './pages/chat/chat.module#ChatPageModule',
  //   canActivate: [MemberGuard]
  // },
  { path: 'manage', 
      loadChildren: './pages/manage/manage.module#ManagePageModule',
      canActivate: [AdminGuard]
  },
  { path: 'display', 
      loadChildren: './pages/display/display.module#DisplayPageModule',
      canActivate: [AdminGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule],
  providers: [AuthGuard, AdminGuard, MemberGuard]
})
export class AppRoutingModule {}
