import { NgModule } from '@angular/core';
import { DocPipe } from '../services/firestore/doc.pipe';
import { MinuteSecondsPipe } from '../services/jeopardy/minute-seconds.pipe';

@NgModule({
    imports:        [],
    declarations:   [DocPipe, MinuteSecondsPipe],
    exports:        [DocPipe, MinuteSecondsPipe],
})

export class PipeModule {

    static forRoot() {
        return {
            ngModule: PipeModule,
            providers: [],
        };
    }
}
