import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { tap, map, take } from 'rxjs/operators';

@Injectable()
export class MemberGuard implements CanActivate {

    constructor(private auth: AuthService, private router: Router) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
            return this.auth.user$.pipe(
                take(1),
                map(user => user && user.roles.member ? true : false),
                tap(isAdmin => {
                    if (!isAdmin) {
                        console.error('Access denied - Must be a member')
                        this.router.navigate(['/login']);
                        return(false);
                    }
                })
            );

        }
}
