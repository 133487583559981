import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { switchMap, first, map } from 'rxjs/operators';
import { User } from './user'; 

/*
import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
*/

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    user$: Observable<User>;
    uid: string;

    constructor(private afAuth: AngularFireAuth,
        private afs: AngularFirestore,
        private router: Router) {

        //// Get auth data, then get firestore user document || null
        this.user$ = this.afAuth.authState.pipe(
            switchMap(user => {
                if (user) {
                    this.uid = user.uid;
                    return this.afs.doc<User>(`userProfiles/${user.uid}`).valueChanges()
                } else {
                    return of(null)
                }
            }))
    }

    getUser() {
        return this.user$.pipe(first()).toPromise();
    }

    getUid() {
        return this.uid;
    }

    ///// Login/Signup //////
    
    // Login anonymously - Sort of, expects data for the profile
    anonymousLogin(email: string, firstName: string, lastName: string) {
        return this.afAuth.auth.signInAnonymously()
            .then((credential) => { 
                console.log("successful login");
                this.updateUserDataAnon(credential.user, email, firstName, lastName);
            })
            .catch(error => console.log(error));
    }

    // Login user with email/password
    loginUser(email: string, password: string): Promise<firebase.auth.UserCredential> {
        return this.afAuth.auth.signInWithEmailAndPassword(email, password);
    }
    
    // Signup user with email/password and save to database.
    signupUser(email: string, password: string): Promise<any> {
        return this.afAuth
            .auth
            .createUserWithEmailAndPassword(email, password)
            .then((credential) => {
                this.updateUserData(credential.user) 
            })
            .catch(error => {
                console.error(error);
                throw new Error(error);
            });
    }

    // Reset a password for a email/password type login
    resetPassword(email:string): Promise<void> {
      return this.afAuth.auth.sendPasswordResetEmail(email);
    }

    googleLogin() {
        const provider = new firebase.auth.GoogleAuthProvider()
        return this.oAuthLogin(provider);
    }

    private oAuthLogin(provider) {
        return this.afAuth.auth.signInWithPopup(provider)
            .then((credential) => {
                this.updateUserData(credential.user)
            })
    }

    // Log out of all types of logins
    logoutUser() {
        return this.afAuth.auth.signOut()
    }

    private updateUserData(user, firstName="", lastName="") {
        // Sets user data to firestore on signup
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(`userProfiles/${user.uid}`);
        const data: User = {
            uid: user.uid,
            email: user.email,
            firstName: firstName,
            lastName: lastName,
            roles: {
                member: true
            }
        }
        return userRef.set(data, { merge: true })
    }
    
    private updateUserDataAnon(user, email: string, firstName: string, lastName: string) {
        // Sets user data to firestore on signup
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(`userProfiles/${user.uid}`);
        const data: User = {
            uid: user.uid,
            email: email,
            firstName: firstName,
            lastName: lastName,
            roles: {
                member: true
            }
        }
        return userRef.set(data, { merge: true })
    }
    
    isRole(user: User, role: string): boolean {
        if (!user) return false
        if ( user.roles[role] ) {
            return true
        }
        return false
    }

    ///// Role-based Authorization //////
    
    canRead(user: User): boolean {
        const allowed = ['admin', 'editor', 'member']
        return this.checkAuthorization(user, allowed)
    }

    canEdit(user: User): boolean {
        const allowed = ['admin', 'editor'];
        return this.checkAuthorization(user, allowed)
    }

    canDelete(user: User): boolean {
        const allowed = ['admin']
        return this.checkAuthorization(user, allowed)
    }

    // determines if user has matching role
    private checkAuthorization(user: User, allowedRoles: string[]): boolean {
        if (!user) return false
        for (const role of allowedRoles) {
            if ( user.roles[role] ) {
                return true
            }
        }
        return false
    }
}
